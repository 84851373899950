export const enum AppleArtistDetailType {
  Name = 0,

  Image = 1,

  Url = 2,

};

export const enum ApprovalState {
  None = 0,

  Requested = 1,

  Approved = 2,

};

export const enum ArtistRoles {
  Contributor = 2,

  Circus_Artist = 3,

  Comedian = 4,

  Conductor = 5,

  DJ = 6,

  Featuring = 7,

  Key_Talent = 8,

  Main_Artist = 9,

  Mixing_Artist = 10,

  Original_Artist = 11,

  Performer = 12,

  Project_Artist = 13,

  Remixed_Artist = 14,

  With = 15,

  Soloist = 16,

  Remixer = 17,

};

export const enum AspNetRoles {
  TenantAdmin = 1,

  CloudAdmin = 9,

  DevUser = 10,

  ImpersonateUser = 11,

};

export const enum AssetTypeGroups {
  Image = 1,

  MIDI = 2,

  SheetMusic = 3,

  Software = 4,

  SoundRecording = 5,

  Text = 6,

  Video = 7,

  UserDefinedResource = 8,

};

export const enum AssetTypes {
  AudioStem = 1,

  MusicalWorkReadalongSoundRecording = 2,

  MusicalWorkSoundRecording = 3,

  NonMusicalWorkReadalongSoundRecording = 4,

  NonMusicalWorkSoundRecording = 5,

  SpokenWordSoundRecording = 6,

  LongFormMusicalWorkVideo = 7,

  LongFormNonMusicalWorkVideo = 8,

  ShortFormMusicalWorkVideo = 9,

  ShortFormNonMusicalWorkVideo = 10,

  BackCoverImage = 11,

  BookletBackImage = 12,

  BookletFrontImage = 13,

  DocumentImage = 14,

  FrontCoverImage = 15,

  Icon = 16,

  Logo = 17,

  Photograph = 18,

  Poster = 19,

  TrayImage = 20,

  Unknown = 21,

  VideoScreenCapture = 22,

  Wallpaper = 23,

  Caption = 24,

  EBook = 25,

  LinerNotes = 26,

  LyricText = 27,

  NonInteractiveBooklet = 28,

  TextDocument = 29,

  InteractiveBooklet = 30,

  MusicalWorkBasedGame = 31,

  NonMusicalWorkBasedGame = 32,

  Screensaver = 33,

  AppleMotionArtSquare = 37,

  AppleMotionArtPortrait = 38,

};

export const enum BankTransactionTypes {
  UK = 1,

  International = 2,

  Consolidated = 3,

  /**FX-Payment*/
  FX_Payment = 4,

};

export const enum CollaboratorType {
  Asset = 0,

  Format = 1,

  Client = 2,

  ParentLabel = 3,

  Label = 4,

};

export const enum CommercialModels {
  AdvertisementSupportedModel = 1,

  AsPerContract = 2,

  DeviceFeeModel = 3,

  FreeOfChargeModel = 4,

  PayAsYouGoModel = 5,

  PerformanceRoyaltiesModel = 6,

  RightsClaimModel = 7,

  SubscriptionModel = 8,

  Unknown = 9,

  UserDefined = 10,

};

export const enum CompilationTypes {
  InternalCompilation = 1,

  NonInternalCompilation = 2,

  NotCompiled = 3,

};

export const enum DataSets {
  Clicks = 0,

  Landing = 1,

  Presave = 2,

};

export const enum DeliveryStates {
  Paused = 1,

  Failed = 2,

  Finished = 3,

  Queued = 4,

  Active = 7,

};

export const enum DeliveryTypes {
  ADD = 1,

  UPDATEALL = 2,

  UPDATEXML = 3,

  DELETE = 4,

  TAKEDOWN = 5,

};

export const enum Destinations {
  Amazon = 1,

  iTunes = 2,

  Amazon_MP3 = 3,

  Official_Store = 4,

  Beatport = 5,

  Juno = 6,

  Apple_Music = 7,

  Spotify = 8,

  Deezer = 9,

  Google_Play = 10,

  Amazon_Music = 11,

  Soundcloud = 12,

  YouTube = 13,

  Tidal = 14,

  Vevo = 15,

  Walmart = 16,

  Best_Buy = 17,

  fnac = 18,

  Rough_Trade = 19,

  HMV = 20,

  qobuz = 21,

  Tesco = 22,

  Saavn = 23,

  Vinyl = 24,

  Tower_Records = 25,

  Bleep = 26,

  Music_Glue = 27,

  CD = 28,

  Traxsource = 29,

  pandora = 30,

  vimeo = 31,

  boomkat = 32,

  ticketmaster = 33,

  Tencent = 34,

  iHeartRadio = 35,

  Tour_Dates = 36,

  Apple_Podcasts = 37,

  audible = 38,

  Shazam = 39,

  Sainsburys = 40,

  Asda = 41,

  YouTube_Music = 42,

  Tickets = 43,

  Levykauppa = 44,

  Genius = 45,

  Kameel = 46,

  Gospel_NL = 47,

  Castbox = 48,

  Pocket_Casts = 49,

  Stitcher = 50,

  Equipping_The_Church = 51,

  Eden = 52,

  Wordery = 53,

  Website = 54,

  Revived = 55,

  David_C_Cook = 56,

  Book_Depository = 57,

  Just_Giving = 58,

  IJM = 59,

  Eventbrite = 60,

  BandCamp = 61,

  ICF_Store = 62,

  SCH_Shop = 63,

  AXS = 64,

  Facebook = 65,

  /**Gigs & Tours*/
  Gigs_Tours = 66,

  Go_Fund_Me = 67,

  Instagram = 68,

  OnlyFans = 69,

  Patreon = 70,

  Pinterest = 71,

  SeeTickets = 72,

  SnapChat = 73,

  Ticketweb = 74,

  Tumblr = 75,

  Twickets = 76,

  Twitch = 77,

  Twitter = 78,

  Virgin_Money_Giving = 79,

  Target = 80,

  Essential_Christian = 81,

  YADA = 82,

  TikTok = 83,

  Mad_Trust = 84,

  /**Barnes & Noble*/
  Barnes_Noble = 85,

  Christianbook = 86,

  Indiebound = 87,

  Mardel = 88,

  Mozaiek = 89,

  Banquet_Records = 90,

  Resident_Music = 91,

  Piccadilly_Records = 92,

  LP_Cafe = 93,

  Specialist_Subject = 94,

  Slide = 95,

  Slugs_and_Bugs = 96,

  Faith_The_Album = 97,

  Faith_The_Book = 98,

  Email_Sign_Up = 99,

  Audiomack = 100,

  Boomplay = 101,

  WHSmith = 102,

  Screwdriver_Records = 103,

  Rarewaves = 104,

  OpenSea = 105,

  Plaid_Room_Records = 106,

  Soul_Brother = 107,

  Drift = 108,

  What_Records = 109,

  Limited_Addition = 110,

  Stranger_Than = 111,

  Norman_Records = 112,

  Five_Rise_Records = 113,

  Phoenix_Sound = 114,

  Low_Port_Music = 115,

  Assai_Records = 116,

  Bear_Tree_Records = 117,

  Deluxe_Newspaper = 118,

  Elsewhere_Records = 119,

  Fopp_Records = 120,

  Jumbo_Records = 121,

  Phonica_Records = 122,

  Spinning_Discs_Sheffield = 123,

  Monorail_Music = 124,

  Cvents = 125,

  Outbreakband_Shop = 126,

  Vinyl_Whistle = 127,

  Sister_Ray = 128,

  YouVersion = 129,

  Proper_Music = 130,

  Crash_Records = 131,

  Strip_Joint = 132,

  Horizons_Music = 133,

  Chalkys = 134,

  Etsy = 135,

  Serenade = 136,

  Wonderland = 137,

  Townsend_Music = 138,

  Claro_Musica = 139,

  Head_Records = 140,

  Disk_Union = 142,

  Spindizzy = 143,

  Music_Zone = 144,

  Golden_Discs = 145,

  /**Ben O’Neill*/
  Ben_O_Neill = 146,

  TopMusic = 147,

  /**Good-Natured*/
  Good_Natured = 148,

  /**Indie Stores (US)*/
  Indie_Stores_US_ = 161,

  /**Spotify Pre-Save*/
  Spotify_Pre_Save = 162,

  Dinked = 163,

  Record_Store_Day_UK = 164,

  QQ = 165,

  Joox = 166,

  Platomania = 168,

  Sounds_Venlo = 169,

  Records_on_Vinyl = 170,

  Mailing_List_Sign_Up = 171,

  Merch = 172,

  Slinky = 173,

  Record_Store_Day_USA = 174,

  Amazon_USA = 175,

  Vinyl_Tap = 180,

};

export const enum FileType {
  CSV = 0,

  Main = 1,

  XLSX = 1,

  Mead = 2,

  Pie = 3,

};

export const enum FormatTypeGroups {
  Album = 1,

  Single = 2,

  Video = 3,

  Merchandise = 4,

  Image = 9,

  Game = 10,

};

export const enum FormatTypes {
  Artist_Album = 4,

  /**Artist Single (Multiple Tracks)*/
  Artist_Single_Multiple_Tracks_ = 5,

  Artist_EP = 6,

  Mixed_Media_Artist_Album = 7,

  Box_Set = 8,

  Compilation_Album = 9,

  Classical_Album = 10,

  Premium_Music_Video = 11,

  Lyric_Video = 12,

  Packshot_Video = 13,

  Digital_Concert_Video = 14,

  Digital_Feature_Film = 15,

  Music_Video_Trailer = 16,

  Promo_Video = 17,

  Non_Music_Video_Trailer = 18,

  Merchandise = 19,

  Compilation_Single = 20,

  Compilation_EP = 21,

  Book = 22,

  CD_Album = 23,

  /**12" Album*/
  _12_Album = 24,

  /**CD/DVD Album*/
  CD_DVD_Album = 25,

  /**CD/Blu-Ray Album*/
  CD_Blu_Ray_Album = 26,

  /**CD/DVD/Blu-Ray Album*/
  CD_DVD_Blu_Ray_Album = 27,

  /**10" Album*/
  _10_Album = 29,

  Enhanced_CD_Album = 30,

  Cassette_Album = 31,

  CD_Single = 32,

  /**7" Single*/
  _7_Single = 33,

  /**10" Single*/
  _10_Single = 34,

  /**12" Single*/
  _12_Single = 35,

  CD_EP = 36,

  Super_Audio_CD = 37,

  Cassette_Single = 38,

  DVD = 39,

  /**Blu-Ray*/
  Blu_Ray = 40,

  /**Blu-Ray/DVD*/
  Blu_Ray_DVD = 41,

  Ringtone = 42,

  Immersive_Audio_Artist_Album = 43,

  /**Immersive Audio Artist Single (Multiple Tracks)*/
  Immersive_Audio_Artist_Single_Multiple_Tracks_ = 44,

  Immersive_Audio_Artist_EP = 45,

  Single_Track_Release = 46,

  Immersive_Single_Track_Release = 47,

};

export const enum ImageRoles {
  Profile = 1,

  Banner = 2,

  Press = 4,

};

export const enum LogLevelEnum {
  Debug = 0,

  Info = 1,

  Warn = 2,

  Error = 3,

  Critical = 4,

};

export const enum MediaPlayerPosition {
  Standard = 0,

  Above_Cover_Art = 1,

  Below_Cover_Art = 2,

};

export const enum NotificationType {
  Web = 0,

  Push = 1,

  Email = 2,

};

export const enum ParentalWarningTypes {
  Explicit = 1,

  ExplicitContentEdited = 2,

  NoAdviceAvailable = 3,

  NotExplicit = 4,

  Unknown = 5,

};

export const enum PartTypeCategories {
  Manufacturing = 1,

  Production = 2,

  Other = 3,

};

export const enum PermissionIdType {
  FormatIDs = 0,

  LabelIDs = 1,

  ParentLabelIDs = 2,

  ClientIDs = 3,

  TenantIDs = 4,

};

export const enum PitchCategories {
  Priority = 1,

  Standard = 2,

  High_Priority = 3,

  Christian_Priority = 4,

  Priority_inc_Dance = 5,

  Dance_Only_Priority = 6,

  Custom = 7,

  Undefined = 8,

};

export const enum PitchingTagCategories {
  Category = 1,

  Moods = 2,

  Music_Cultures = 3,

  Song_Styles = 4,

  Instruments = 5,

  Song_Version = 6,

};

export const enum PixelTypes {
  X_Pixel_Id = 1,

  Snapchat_Pixel_Id = 2,

  Meta_Pixel_Id = 3,

  Google_Analytics_GA4_ID = 4,

  TikTok_Pixel_Id = 5,

  Reddit_Pixel_Id = 6,

  Google_Tag = 7,

};

export const enum PlatformDeliveryGroups {
  Apple = 2,

  Amazon_Music = 3,

  Spotify = 5,

  Rdio = 6,

  Deezer = 7,

  Beatport = 8,

  Tekked = 9,

  Vidzone = 10,

  _7Digital = 11,

  DDEX = 13,

  Juno_Download = 14,

  Medianet = 15,

  eMusic = 16,

  Napster = 17,

  KDigital = 18,

  Love_DA = 19,

  TrackItDown = 20,

  Google_Play = 22,

  Shazam = 23,

  Millward_Brown = 24,

  PPL = 25,

  Catco = 26,

  Omnifone = 27,

  Tidal = 28,

  MixRadio = 29,

  Simfy_Africa = 30,

  Zvooq = 31,

  Qobuz = 32,

  /**nuhbe.com*/
  nuhbe_com = 33,

  Groove = 36,

  Label_Worx = 37,

  YouTube_Music = 38,

  /**Christianbook.com*/
  Christianbook_com = 40,

  Pandora = 43,

  SoundCloud = 44,

  Freshwater_Worship = 45,

  KKbox = 46,

  David_C_Cook = 47,

  Findspire = 48,

  Slacker_Radio = 49,

  Youtube_Art_Track_Videos = 50,

  Absolute_Archive = 51,

  SoundExchange = 52,

  JioSaavn = 53,

  Neurotic_Media = 54,

  Downtown = 55,

  Yonder = 56,

  Akazoo = 61,

  Bleep_Labelworx = 81,

  Digital_Tunes = 82,

  Digitally_Imported = 83,

  /**DJShop.de*/
  DJShop_de = 84,

  HardDanceMP3 = 85,

  /**Hardstyle.com*/
  Hardstyle_com = 86,

  Pulselocker = 87,

  Pyro = 88,

  Traxsource_via_LabelWorx = 89,

  /**Tunes4DJs.com*/
  Tunes4DJs_com = 90,

  Wasabeat = 91,

  What_People_Play = 92,

  Flipagram = 94,

  BPI_Antipiracy = 96,

  PPCA = 97,

  Abramus = 98,

  SIMIM = 99,

  SENA = 100,

  Gramo = 101,

  SCPP = 102,

  Audible_Magic = 105,

  iHeartMedia = 108,

  Qanawat = 109,

  SiriusXM = 110,

  United_Media_Agency = 111,

  Traxsource = 114,

  Bleep = 116,

  YouTube_Content_ID = 117,

  Cherry_Red_Library = 118,

  AWA = 119,

  Dubset = 123,

  Muso = 124,

  Netease = 127,

  Alibaba_Music_Group = 128,

  TempPackshotVideos = 129,

  Tencent = 130,

  Facebook = 133,

  YouTube = 134,

  AMI_Entertainment = 135,

  Gramex = 137,

  ItsRight = 138,

  Connect = 139,

  Yandex = 140,

  FUGA = 141,

  ZPAV = 142,

  Prophone = 143,

  IMAGIA = 144,

  Sampra = 145,

  IPF = 146,

  IFPI = 147,

  /**Lick'd*/
  Lick_d = 149,

  Intergram = 150,

  Zapraf = 151,

  Mahasz = 152,

  Tesla = 154,

  /**BMAT (ARM Only)*/
  BMAT_ARM_Only_ = 155,

  Gracenote = 156,

  Boomplay = 160,

  Bytedance = 163,

  Curve_Royalties = 164,

  BMAT = 165,

  LaIPA = 166,

  Music_Island = 167,

  Anghami = 168,

  PlayNetwork = 169,

  Amazon_Music_Video = 170,

  Facebook_Video = 171,

  YouTube_Music_Video = 172,

  Bandcamp = 173,

  Elatel = 174,

  GVL = 176,

  Pixel_Lyric_Video_Creation = 177,

  ACRCloud = 180,

  Soundmouse = 181,

  PPI = 183,

  EFY = 184,

  GRAMEX_DK = 185,

  VPL = 186,

  Jaxsta = 187,

  Mood_Media = 188,

  Feed_Media_Group = 189,

  Tidal_Immersive_Audio = 190,

  Adaptr = 191,

  Mixcloud_Ltd = 192,

  Joox = 193,

  SberZvuk = 194,

  Musicmap = 195,

  Claro_Musica = 196,

  Prazor = 197,

  Amazon_Music_Immersive_Audio = 198,

  AllRock = 200,

  Cantine = 201,

  /**Feelment (Maestro Pro)*/
  Feelment_Maestro_Pro_ = 202,

  Grandpad_Download = 203,

  Grandpad_Radio_Stream = 204,

  HomeWAV = 205,

  Jazzed = 206,

  Musicube = 207,

  Musixmatch = 208,

  NEC = 209,

  PEX = 210,

  Roxi_Music = 211,

  Snap = 212,

  Soundhound = 213,

  Soundtrack = 214,

  /**Stationhead - Downloads*/
  Stationhead_Downloads = 215,

  Trebel_Music = 216,

  Tunesat = 217,

  Xite = 218,

  Canva = 219,

  MCPS = 413,

  Bucks_Music = 416,

  Nuuday = 417,

  Top_Music = 418,

  AGEDI = 419,

  RDx = 420,

  Universal_Distribution_UK = 516,

  Proper_Music_Distribution_UK = 518,

  Proper_Music_Distribution_International = 519,

  Music_Glue = 520,

  Apple_Immersive_Audio = 522,

  Plastic_Head_Music_Distribution = 528,

  Townsend_Music = 529,

  Rythm = 530,

  Audiomack = 531,

  Peloton = 532,

  Spotify_DDEX_43_Test = 533,

  Soproq = 534,

};

export const enum Platforms {
  Anthology = 1,

  iTunes = 2,

  Amazon_Music = 3,

  Spotify = 5,

  Rdio = 6,

  Deezer = 7,

  Beatport = 8,

  Tekked = 9,

  Vidzone = 10,

  _7Digital = 11,

  DDEX = 13,

  Juno_Download = 14,

  Medianet = 15,

  eMusic = 16,

  Napster = 17,

  KDigital = 18,

  Love_DA = 19,

  TrackItDown = 20,

  Google_Play = 22,

  Shazam = 23,

  Millward_Brown = 24,

  PPL = 25,

  Catco = 26,

  Omnifone = 27,

  Tidal = 28,

  MixRadio = 29,

  Simfy_Africa = 30,

  Zvooq = 31,

  Qobuz = 32,

  /**nuhbe.com*/
  nuhbe_com = 33,

  Groove = 36,

  Label_Worx = 37,

  YouTube_Music = 38,

  /**Christianbook.com*/
  Christianbook_com = 40,

  Pandora = 43,

  SoundCloud = 44,

  Freshwater_Worship = 45,

  KKbox = 46,

  David_C_Cook = 47,

  Findspire = 48,

  Slacker_Radio = 49,

  YouTube_Art_Track_Videos = 50,

  Absolute_Archive = 51,

  SoundExchange = 52,

  JioSaavn = 53,

  Neurotic_Media = 54,

  Downtown = 55,

  Yonder = 56,

  HMV = 57,

  LaMusica = 58,

  Technics_TRACKS = 59,

  /**Sainsbury's Entertainment*/
  Sainsbury_s_Entertainment = 60,

  Akazoo = 61,

  Apple_Music = 62,

  HTC_One = 63,

  Kobo = 64,

  /**Last.FM*/
  Last_FM = 65,

  Mach = 66,

  Me2u_Music = 67,

  Mi_Tracks = 69,

  Mixette = 70,

  MusicQubed = 71,

  Onkyo = 72,

  OpenLIVE = 73,

  Overmax = 74,

  Raku = 75,

  ROK_Mobile = 76,

  Tonspion = 77,

  TriPlay = 78,

  Tunewiki = 79,

  The_Overflow = 80,

  Bleep_Labelworx = 81,

  Digital_Tunes = 82,

  Digitally_Imported = 83,

  /**DJShop.de*/
  DJShop_de = 84,

  HardDanceMP3 = 85,

  /**Hardstyle.com*/
  Hardstyle_com = 86,

  Pulselocker = 87,

  Pyro = 88,

  Traxsource_via_LabelWorx = 89,

  /**Tunes4DJs.com*/
  Tunes4DJs_com = 90,

  Wasabeat = 91,

  What_People_Play = 92,

  Flipagram = 94,

  BPI_Antipiracy = 96,

  PPCA = 97,

  Abramus = 98,

  SIMIM = 99,

  SENA = 100,

  Gramo = 101,

  SCPP = 102,

  Audible_Magic = 105,

  iHeartMedia = 108,

  Qanawat = 109,

  SiriusXM = 110,

  United_Media_Agency = 111,

  SYB = 112,

  Traxsource = 114,

  Soundtrack_Your_Brand = 115,

  Bleep = 116,

  YouTube_ContentID = 117,

  Cherry_Red_Library = 118,

  AWA = 119,

  Dubset = 123,

  Muso = 124,

  Facebook_Audio_Fingerprint = 125,

  Facebook_Audio_Library = 126,

  Netease = 127,

  Alibaba_Music_Group = 128,

  TempPackshotVideos = 129,

  Tencent = 130,

  Facebook = 133,

  YouTube = 134,

  AMI_Entertainment = 135,

  Gramex = 137,

  ItsRight = 138,

  Connect = 139,

  Yandex = 140,

  FUGA = 141,

  ZPAV = 142,

  Prophone = 143,

  IMAGIA = 144,

  Sampra = 145,

  IPF = 146,

  IFPI = 147,

  /**Lick'd*/
  Lick_d = 149,

  Intergram = 150,

  Zapraf = 151,

  Mahasz = 152,

  Tesla = 154,

  /**BMAT (ARM Only)*/
  BMAT_ARM_Only_ = 155,

  Gracenote = 156,

  HMV_Music_Japan = 157,

  KDDI = 158,

  /**J:Com*/
  J_Com = 159,

  Boomplay = 160,

  TikTok = 161,

  Project_M = 162,

  Bytedance = 163,

  Curve_Royalties = 164,

  BMAT = 165,

  LaIPA = 166,

  Music_Island = 167,

  Anghami = 168,

  PlayNetwork = 169,

  Amazon_Music_Video = 170,

  Facebook_Video = 171,

  YouTube_Music_Video = 172,

  Bandcamp = 173,

  Elatel = 174,

  Lyric_Video_Creator = 175,

  GVL = 176,

  Ruance_Lyric_Video_Creation = 177,

  /**Facebook Video Streaming + Library*/
  Facebook_Video_Streaming_Library = 178,

  Facebook_Video_Fingerprint = 179,

  ACRCloud = 180,

  Soundmouse = 181,

  PPI = 183,

  EFY = 184,

  GRAMEX_DK = 185,

  VPL = 186,

  Jaxsta = 187,

  Mood_Media = 188,

  Feed_Media_Group = 189,

  Peloton = 190,

  Adaptr = 191,

  Mixcloud = 192,

  Joox = 193,

  SberZvuk = 194,

  Musicmap = 195,

  Claro_Musica = 196,

  Prazor = 197,

  Amazon_3D_Audio = 198,

  AllRock = 200,

  Cantine = 201,

  /**Feelment (Maestro Pro)*/
  Feelment_Maestro_Pro_ = 202,

  Grandpad_Download = 203,

  Grandpad_Radio_Stream = 204,

  HomeWAV = 205,

  Jazzed = 206,

  Musicube = 207,

  Musixmatch = 208,

  NEC = 209,

  PEX = 210,

  Roxi_Music = 211,

  Snap = 212,

  Soundhound = 213,

  Soundtrack = 214,

  /**Stationhead - Downloads*/
  Stationhead_Downloads = 215,

  Trebel_Music = 216,

  Tunesat = 217,

  Xite = 218,

  Canva = 219,

  Beats_Music = 304,

  Electric_Jukebox = 404,

  Guvera = 405,

  i_am_plus = 406,

  /**Pulselocker (Delete)*/
  Pulselocker_Delete_ = 407,

  Resso = 408,

  Saavn = 409,

  Triller = 410,

  Vevo = 411,

  Weyv = 412,

  MCPS = 413,

  LSG = 415,

  Bucks_Music = 416,

  Nuuday = 417,

  Top_Music = 418,

  AGEDI = 419,

  RDx = 420,

  Awal = 501,

  Ingrooves = 502,

  Kudos = 503,

  Spotify_Discovery = 505,

  Universal_Distribution_UK = 516,

  Universal_Distribution_Eire = 517,

  Proper_Music_Distribution_UK = 518,

  Proper_Music_Distribution_International = 519,

  Music_Glue = 520,

  iTunes_Immersive = 521,

  Apple_Music_Immersive = 522,

  Tidal_Immersive_Audio = 524,

  Muzu = 527,

  Plastic_Head_Music_Distribution = 528,

  Townsend_Music = 529,

  Twitch = 531,

  Wimp = 532,

  Dial = 533,

  Universal_Music_Digital_Services = 534,

  Xbox = 535,

  Merlin = 536,

  Believe = 537,

  Indie_Mobile = 538,

  Universal_Music_Digital_International = 539,

  YouTube_Subscription = 540,

  YouTube_Ad_Revenue = 541,

  Pinterest = 546,

  YouTube_Shorts = 647,

  Amazon = 648,

  Instagram = 649,

  Snapchat = 650,

  X = 651,

  Rythm = 752,

  Audiomack = 853,

  Spotify_DDEX_43_Test = 954,

  Soproq = 955,

};

export const enum PolicyGroups {
  Monetise = 1,

  Block = 2,

  Track = 3,

  Mute = 4,

};

export const enum PreSaveOperation {
  Add = 0,

  Remove = 1,

};

export const enum PriceTierTypes {
  Track = 1,

  Single = 2,

  Album = 3,

  Video = 4,

  Concert = 5,

  Film = 6,

};

export const enum RateCategories {
  Physical_Distribution = 1,

  Digital_Distribution = 2,

  Neighbouring_Rights = 3,

  Other = 4,

  Direct_to_Consumer = 5,

};

export const enum RechargeCategories {
  Manufacture = 1,

  Recharge = 2,

  Other_Sales = 3,

};

export const enum ReleaseDealTypes {
  Main_Deal = 1,

  Territory_Exclusive = 2,

  Platform_Exclusive = 3,

  /**Pre-Order*/
  Pre_Order = 5,

  Price_Intervals = 6,

  Territory_Pre_Order = 7,

  Timed_Release = 8,

  Track_Deal = 9,

  Platform_Pre_Order = 10,

};

export const enum ReleaseInactiveState {
  AddFormat = 0,

  TakeDownFormat = 1,

  TakeDownStores = 2,

  TakeDownStoresAndRights = 3,

  AssetRightsEnd = 4,

};

export const enum ReleaseResourceTypes {
  PrimaryResource = 1,

  SecondaryResource = 2,

};

export const enum ReleaseTypes {
  Album = 1,

  AlertToneRelease = 2,

  AsPerContract = 3,

  AudioBookRelease = 4,

  AudioDramaRelease = 5,

  BackCoverImageRelease = 6,

  BookletBackImageRelease = 7,

  BookletFrontImageRelease = 8,

  BookletRelease = 9,

  Bundle = 10,

  ClassicalAlbum = 11,

  ClassicalDigitalBoxedSet = 12,

  ClassicalMultimediaAlbum = 13,

  ConcertVideo = 14,

  DigitalBoxSetRelease = 15,

  DjMix = 16,

  Documentary = 17,

  Drama = 18,

  DramaticoMusicalVideoRelease = 19,

  EBookRelease = 20,

  EP = 21,

  Episode = 22,

  FeatureFilm = 23,

  KaraokeRelease = 24,

  LiveEventVideo = 25,

  LogoRelease = 26,

  LongFormMusicalWorkVideoRelease = 27,

  LongFormNonMusicalWorkVideoRelease = 28,

  LyricSheetRelease = 29,

  MultimediaAlbum = 30,

  MultimediaDigitalBoxedSet = 31,

  MultimediaSingle = 32,

  MusicalWorkBasedGameRelease = 33,

  NonMusicalWorkBasedGameRelease = 34,

  PlayList = 35,

  RingbackToneRelease = 36,

  RingtoneRelease = 37,

  Season = 38,

  Series = 39,

  SheetMusicRelease = 40,

  ShortFilm = 41,

  Single = 42,

  SingleResourceRelease = 43,

  StemBundle = 44,

  UserDefined = 45,

  VideoAlbum = 46,

  VideoMastertoneRelease = 47,

  VideoSingle = 48,

  WallpaperRelease = 49,

};

export const enum ResourceConnectionTypes {
  Tenant = 2,

  Client = 3,

  Parent_Label = 4,

  Label = 5,

  Contributor = 6,

  Publisher = 7,

  Publisher_Group = 8,

};

export const enum ResourceType {
  AccessLevel = 0,

  AccountingCategory = 1,

  AgeCertification = 2,

  ArtistRole = 3,

  AssetType = 4,

  BankTransactionType = 5,

  BusinessMusicalWorkRole = 6,

  ClientSettings = 7,

  ColorSpace = 8,

  CommercialModel = 9,

  ContributorRole = 10,

  Currency = 11,

  PhysicalPriceTypes = 12,

  DdexPartyIds = 13,

  DealType = 14,

  FormatCheckType = 15,

  FormatCheckStatus = 16,

  FormatStatusTags = 17,

  FormatType = 18,

  FormatTypeGroup = 19,

  Gender = 20,

  Genre = 21,

  Instrument = 22,

  JsonSchemas = 23,

  Language = 24,

  McpslicenceType = 25,

  MetadataType = 26,

  MusicalWorkType = 27,

  ParentalType = 28,

  PartType = 29,

  PartTypeCategory = 30,

  Permission = 31,

  PhysicalFormatDimension = 32,

  PhysicalFormatMapping = 33,

  PitchCategory = 34,

  PitchMethod = 35,

  Platform = 36,

  PlatformBusinessType = 37,

  PlatformDeliveryAssetType = 38,

  PlatformDeliveryGroup = 39,

  PlatformDeliveryServerType = 40,

  PlatformDisplayGroup = 41,

  PolicyType = 42,

  Price = 43,

  Publisher = 44,

  PublisherRole = 45,

  Rate = 46,

  RechargeCategory = 47,

  RegionCode = 48,

  ResourceType = 49,

  RightsControllerType = 50,

  RightsControllerRole = 51,

  SaasProduct = 52,

  Service = 53,

  SupplierAddressType = 54,

  SupplierContactType = 55,

  SystemPlatformPolicy = 56,

  TaxType = 57,

  TaxTypeOption = 58,

  TeamMemberRole = 59,

  Territory = 60,

  TicketsCategory = 61,

  TicketsPartner = 62,

  TicketsPriority = 63,

  TicketsProduct = 64,

  TicketsRating = 65,

  TicketsStatus = 66,

  TicketsTag = 67,

  Timezone = 68,

  Topic = 69,

  TopicCategory = 70,

  UseType = 71,

  WriterRole = 72,

  NewsStoryType = 73,

  ReportingDocType = 74,

};

export const enum ResourceTypeGroups {
  Social_Media = 2,

  Identifiers = 3,

  Websites = 4,

  Marketing_Pixels = 12,

  Affiliate_Links = 13,

  Mailchimp = 14,

};

export const enum ResourceTypes {
  Official_Website = 1,

  BBC_Music_Id = 2,

  Discogs_Id = 3,

  /**Lyrics URL (Delete)*/
  Lyrics_URL_Delete_ = 4,

  Wikipedia_Id = 5,

  SongKick_Id = 6,

  /**SongKick Id (Delete)*/
  SongKick_Id_Delete_ = 7,

  /**SongKick Tour End Date (DELETE)*/
  SongKick_Tour_End_Date_DELETE_ = 8,

  Music_Brainz_Id = 9,

  YouTube_Channel_Name = 10,

  /**Youtube Password Artist (Delete)*/
  Youtube_Password_Artist_Delete_ = 11,

  YouTube_Channel_Id = 12,

  /**Facebook User Name Artist (Delete)*/
  Facebook_User_Name_Artist_Delete_ = 13,

  Facebook_Id = 14,

  Facebook_Page_Name = 15,

  X_Id = 16,

  Instagram_Id = 17,

  ISNI_Id = 20,

  Spotify_Id = 21,

  Apple_Id = 22,

  Deezer_Id = 23,

  PPL_Performer_Id = 24,

  IPI_Id = 27,

  SoundCloud_Id = 28,

  SCPP_Label_Id = 29,

  LinkedIn_Id = 31,

  /**LinkedIn (Individual) (Delete)*/
  LinkedIn_Individual_Delete_ = 33,

  /**Publisher IPI (Delete)*/
  Publisher_IPI_Delete_ = 35,

  Meta_Pixel_Id = 36,

  Google_Tag = 37,

  TikTok_Pixel_Id = 38,

  Snapchat_Pixel_Id = 39,

  X_Pixel_Id = 40,

  Reddit_Pixel_Id = 41,

  Amazon_Affiliate_Link = 42,

  Apple_Affiliate_Link = 43,

  Music_Glue_Store = 47,

  YouTube_ArtistId = 51,

  TikTok_Profile = 52,

  Amazon_ASIN = 53,

  Pandora_ArtistId = 54,

  LastFM_ArtistId = 55,

  BandsinTown_Id = 57,

  Genius_Lyrics_Artist_Id = 58,

  MusixMatch_ArtistId = 59,

  IPD_Id = 61,

  MCPS_IPA_Id = 62,

  Meta_Artist_Id = 63,

  Beatport_Id = 64,

  Client_Identifier = 66,

  Snapchat_Username = 67,

  TikTok_Pixel_Access_Token = 68,

  Snapchat_Pixel_Access_Token = 69,

  Google_Pixel_Access_Token = 70,

  Google_Analytics_Pixel_Access_Token = 71,

  Reddit_Pixel_Access_Token = 72,

  Meta_Pixel_Access_Token = 73,

  TikTok_Merlin_Pixel_Access_Token = 74,

  SENA_Producer_Id = 75,

  Microsoft_Teams_Tenant_Id = 76,

};

export const enum RightsControllerTypes {
  OriginalOwner = 1,

  SuccessorInTitle = 2,

  NonExclusiveLicensee = 3,

  ExclusiveLicensee = 4,

};

export const enum SlinkyBuildType {
  Full = 0,

  Update = 1,

  Landing = 2,

};

export const enum SlinkyRequestType {
  None = 0,

  Title = 1,

  Format = 2,

};

export const enum StatusTags {
  Draft = 1,

  In_Review = 2,

  Queued = 3,

  Not_Out_Yet = 4,

  In_Market = 5,

  Removed = 6,

  Delivery_Issue = 7,

  Open_Ticket = 8,

  Needs_Approval = 10,

  _2_weeks_left = 11,

};

export const enum SupplierContactTypes {
  Orders = 1,

  Accounts = 2,

  Sales = 3,

  Quotations = 4,

};

export const enum TeamMemberRoles {
  Deal_Creator = 1,

  Label_Manager = 2,

  Digital_Submissions = 3,

  Digital_Marketing = 4,

  Neighbouring_Rights = 5,

  Production = 6,

  Publishing = 7,

  Development = 8,

  Finance = 9,

  Financial_Reporting = 10,

  Sync = 11,

  /**Slinky & PreSave*/
  Slinky_PreSave = 12,

  Physical_Submissions = 13,

  /**Digital Retail & Promotion*/
  Digital_Retail_Promotion = 14,

  Rights_Administration = 15,

  Mechanical_Registrations = 16,

  Board = 17,

  Vevo_Admin = 18,

  Takedowns = 19,

};

export const enum TicketCategories {
  Digital_Product_Request = 1,

  Product_Creation_Issue = 2,

  Technical_Portal_Issue = 3,

  Mechanical_Registration_Issue = 5,

  Physical_Product_Request = 6,

  Agreement_Query = 7,

  Client_Reporting_Query = 8,

  Finance_Query = 9,

  Physical_Stock_Query = 10,

  Label_Manager_Query = 11,

  Management_Query = 12,

  Asset_Request = 13,

  Work_Request = 14,

  Contributor_Request = 15,

  Slinky_Request = 19,

  Technical_Delivery_Issue = 20,

  Rights_Administration = 21,

  Takedown_Request = 22,

  Release_Pitching_Query = 23,

  Neighbouring_Rights_Issue = 24,

  /**Digital Retail & Promotion*/
  Digital_Retail_Promotion = 25,

};

export const enum TicketEvents {
  Add_comment = 2,

  Mail_to_agent = 3,

  Mail_to_customer = 4,

  Mail_to_group = 5,

  Mail_to_last_collaborator = 6,

  Mark_as_spam = 7,

  Assign_to_agent = 8,

  Set_Priority_as = 9,

  Set_Status_as = 10,

  Set_Tag_as = 11,

  Set_Ticket_Type_as = 12,

  Ticket_Created = 16,

  Comment_Created = 17,

  Ticket_Closed = 18,

  Collaborator_Added = 19,

};

export const enum TicketProductTypes {
  FormatId = 1,

  AssetId = 2,

  ContributorId = 3,

  WorkId = 4,

  SlinkyId = 5,

  LabelId = 6,

  ParentLabelId = 8,

  ClientId = 9,

  FormatAssetId = 10,

  TenantId = 11,

};

export const enum TicketStatus {
  Open = 1,

  Pending = 2,

  Answered = 3,

  Resolved = 4,

  Closed = 5,

};

export const enum TitleTypes {
  AbbreviatedDisplayTitle = 1,

  AlternativeTitle = 2,

  DisplayTitle = 3,

  FirstLineOfText = 4,

  FormalTitle = 5,

  GroupingTitle = 6,

  IncorrectTitle = 7,

  MisspelledTitle = 8,

  OriginalTitle = 9,

  SearchTitle = 10,

  SortingTitle = 11,

  TitleAsPart = 12,

  TitleWithoutPunctuation = 13,

  TranslatedTitle = 14,

  Unknown = 15,

  UserDefined = 16,

};

export const enum Topics {
  Digital_Demand = 1,

  Physical_Demand = 4,

  D2C_Demand = 5,

  Playlist_Additions = 6,

  Artist_Socials_Stats = 7,

  Charts = 8,

  Slinky_Ready_to_Edit = 9,

  Slinky_is_Live = 10,

  /**Slinky - Add Physical Releases to Slinky*/
  Slinky_Add_Physical_Releases_to_Slinky = 11,

  Slinky_Click_Uplift = 12,

  Slinky_PreSave_Uplift = 13,

  Artist_Tour_Announced = 14,

  Artist_Live_Tonight = 15,

  Stock_Issues = 16,

  Platform_Coverage = 17,

  Reminder_Out_Now = 18,

  Reminder_Call_Off = 19,

  Reminder_Anniversary = 20,

  Music_Glue_Release_Date_Change = 21,

  Music_Glue_New_format_possible_for_Music_Glue = 22,

  Music_Glue_Stock_Notification = 23,

  Missing_Artist_DSP_Ids = 24,

  Artist_Ids_Not_Verified = 25,

  Apple_Flagged_Streams = 26,

  Apple_Release_In_Review = 27,

  Apple_Excluded_Streams = 28,

  Releases_Failing_to_Deliver_by_Format = 29,

  Releases_Failing_to_Deliver_by_Platform = 30,

  Missing_Socials_for_Main_Artists = 31,

  Missing_Formats_Existing_in_Warehouse = 32,

  Missing_Warehouse_Data = 33,

  Missing_Royalty_Reports = 34,

  Missing_Exchange_Rate = 35,

  Royalty_Uplift_By_Platform = 36,

  Royalty_Uplift_By_Artist = 37,

  Royalty_Uplift_By_Territory = 38,

  Royalty_Uplift_By_Release = 39,

  Royalty_Uplift_by_Asset = 40,

  Royalty_Uplift_By_Type = 41,

  Client_Royalty_Change_for_Label_Manager = 42,

  Post_on_Socials_Slinky_or_out_now = 43,

  Clients_Without_Current_Agreements = 44,

  Delivery_Due_Soon = 45,

  Playlist_Movement = 46,

  Playlist_Removal = 47,

  Delivery_Approved = 48,

  Playlist_Pitching_Reminder = 49,

};

export const enum UserPermissionAccessLevels {
  Tenant = 1,

  Client = 3,

  Parent_Label = 7,

  Label = 15,

  /**Artist & Label*/
  Artist_Label = 31,

  Publisher_Group = 35,

  Infrrd_Publisher_Group_with_Id = 39,

  Infrrd_Publisher_Group_no_Id = 47,

  Publisher = 99,

  Inferred_Publisher = 103,

  /**Inferred Writer & Publisher*/
  Inferred_Writer_Publisher = 111,

  /**Writer & Publisher*/
  Writer_Publisher = 115,

};

export const enum ValidationSeverity {
  Error = 0,

  Warning = 1,

  Info = 2,

};

