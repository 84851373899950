"use client";

import { Platforms } from "../../api/types/autoEnum";
import { defaultBaseUrl } from "../../constants/urls";
import { MobileDeviceOs } from "./slinkyHelpers";

type PresaveUrls = Partial<{
  [key in keyof typeof Platforms]: {
    [key in keyof typeof MobileDeviceOs]?: string;
  };
}>;

const redirectReturnUrl = `${defaultBaseUrl}/api/SlinkyPreSave/PreSaveCallback`;

const ClientId: any = {
  [Platforms.Spotify]: "bb5c4e2c52b54889b0c64e1338483f60",
  [Platforms.YouTube_Music]:
    "1033173099523-sh2uamltulnjd8rgpkc4l05rugmkkqcp.apps.googleusercontent.com",
};

const Scope: any = {
  [Platforms.Spotify]: [
    "user-read-private",
    "user-read-email",
    "playlist-read-private",
    "playlist-read-collaborative",
    "playlist-modify-public",
    "playlist-modify-private",
    "user-library-read",
    "user-library-modify",
    "user-follow-modify",
  ],
  [Platforms.YouTube_Music]: [
    "https://www.googleapis.com/auth/youtube",
    "https://www.googleapis.com/auth/userinfo.profile",
    "https://www.googleapis.com/auth/userinfo.email",
  ],
};

export function getPresaveAuthParams(
  presaveStateString: string,
  platformId: Platforms
): string {
  const params = new URLSearchParams();
  const scopeArr = Scope[platformId];
  const scopeString = scopeArr ? scopeArr.join(" ") : "";
  params.append("client_id", ClientId[platformId]);
  params.append("response_type", "code");
  params.append("redirect_uri", redirectReturnUrl);
  params.append("scope", scopeString);
  params.append("state", presaveStateString);
  if (platformId === Platforms.YouTube_Music) {
    params.append("access_type", "offline");
  }
  return params.toString();
}

export function getRedirectUrl(
  presaveStateString: string,
  platformId: Platforms,
  mobileOs?: MobileDeviceOs
) {
  const presaveAuthParams = getPresaveAuthParams(
    presaveStateString,
    platformId
  );
  const presaveUrls: PresaveUrls = {
    [Platforms.Spotify]: {
      [MobileDeviceOs.Unknown]: `https://accounts.spotify.com/authorize?${presaveAuthParams}`,
      [MobileDeviceOs.Android]: `intent://accounts.spotify.com/inapp-authorize?${presaveAuthParams}#Intent;package=com.spotify.music;scheme=https;end`,
      [MobileDeviceOs.iOS]: `spotify-action://authorize?${presaveAuthParams}`,
    },
    [Platforms.YouTube_Music]: {
      [MobileDeviceOs.Unknown]: `https://accounts.google.com/o/oauth2/v2/auth?${presaveAuthParams}`,
    },
  };
  if (!presaveUrls[platformId]) {
    return "";
  }
  if (
    mobileOs !== undefined &&
    mobileOs !== MobileDeviceOs.Unknown &&
    presaveUrls &&
    presaveUrls[platformId] &&
    presaveUrls[platformId]![mobileOs]
  ) {
    return presaveUrls[platformId]![mobileOs]!;
  } else if (presaveUrls[platformId]) {
    return presaveUrls[platformId]![MobileDeviceOs.Unknown]!;
  } else {
    return "";
  }
}
