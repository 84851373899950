import { Box } from "@mui/material";
import { MarkDownView } from "..";
import { slinkyPrivacySource } from "../slinky";

const PrivacyPolicy = () => {
  return (
    <Box p={3}>
      <MarkDownView source={slinkyPrivacySource} />
    </Box>
  );
};

export default PrivacyPolicy;
