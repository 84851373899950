export const slinkyPrivacySource = `# Slinky Privacy Policy

Absolute Label Services Ltd, trading as Slinky.to, values your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information when you use our services, whether as an End User or a Customer. This policy complies with UK data protection laws and the General Data Protection Regulation (GDPR).

## 1. Definitions

- **End User:** A fan or visitor who uses Slinky pages to access pre-save releases, listen to music, or navigate to external music streaming platforms.
- **Customer:** An individual, artist, or entity who creates and manages Slinky promotional links to distribute and promote music content.

## 2. Information Collected

### For End Users:

When you interact with Slinky pages, we may collect the following information:

- **Technical and Usage Data:** Browser type, operating system, device information, IP address, location derived from IP, page views, clicks, time spent, and actions such as playbacks, previews, or bounces.
- **Contact Information** (only with consent): Email address, first name, and last name, when provided voluntarily by opting into communications or pre-save actions.
- **Social and Music Service Data** (optional): If you connect through third-party platforms (e.g., Facebook, Spotify, Apple Music), we may collect additional information you consent to share, such as music preferences, playlists, top artists, likes, and followers.
- **Tracking Information:** Cookies and other tracking technologies that collect information about your interactions on Slinky pages. You can manage cookie preferences in your browser settings.

### For Customers:

When you register as a Customer to create and manage Slinky links, we collect:

- **Account Information:** First name, last name, email address, company or artist name, and login credentials.
- **Billing and Payment Information:** When relevant, we collect billing details, though payment processing is handled by secure third-party providers.
- **Activity Data:** Actions taken in the Slinky platform, such as link creation, campaign performance metrics, and user engagement data.

## 3. Basis for Processing Your Information

- **Contractual Obligation:** Processing your information is necessary for us to provide you with our services, as outlined in our Terms of Service.
- **Consent:** We rely on your consent for specific marketing communications and any data sharing with social/music services.
- **Legitimate Interests:** We use data to improve our services, secure the platform, and understand user behaviour.
- **Legal Compliance:** We may process your data to comply with regulatory obligations or respond to lawful requests from authorities.

## 4. How We Use Your Information

### For End Users:

We use your data to:

- **Deliver Content and Improve Experience:** Provide relevant pre-save links, redirect to preferred streaming services, and enhance your browsing experience on Slinky pages.
- **Personalise Communication:** With your consent, send you targeted messages or promotions from us or our Customers based on your preferences.
- **Analytics and Insights:** Analyse engagement data to help our Customers understand user behaviour and improve their promotional strategies.
- **Advertising and Remarketing**: Use cookies and tracking technologies, including remarketing pixels, to deliver targeted advertisements based on your browsing behaviour and preferences, where consent is given.

### For Customers:

We use your data to:

- **Provide and Manage Services:** Enable you to create, manage, and track the performance of Slinky links and music promotions.
- **Account Communication:** Send updates related to your account, performance metrics, and service notifications.
- **Service Improvement:** Use aggregated data to refine our offerings, enhance service efficiency, and support product development.
- **Billing and Invoicing:** Process transactions, manage subscriptions, and provide financial documentation.

## 5. Data Sharing and Disclosure

We do not sell personal information. However, we may share your data as follows:

- **With Service Providers:** Trusted third-party vendors who assist in service operations, including payment processors, hosting services, and analytics providers.
- **With Customers:** For End Users, some information (e.g., email address) may be shared with Customers (e.g., artists or labels) for direct communication, but only if you opt-in.
- **Business Transfers:** In the event of a merger, acquisition, or asset sale, your data may be transferred to the new owner.
- **Legal Requirements:** We may disclose your data if required by law or to protect our rights, privacy, safety, or property.

## 6. International Data Transfers

We may transfer and store your data in countries outside of the UK and the European Economic Area (EEA). In such cases, we implement safeguards such as Standard Contractual Clauses to ensure an adequate level of data protection.

## 7. Your Privacy Rights

Under GDPR, you have the following rights:

- **Access:** Request a copy of the personal information we hold about you.
- **Correction:** Request correction of inaccurate or incomplete data.
- **Erasure:** Request deletion of your data, subject to certain legal conditions.
- **Restriction:** Request to restrict processing in certain circumstances.
- **Portability:** Request a structured, machine-readable copy of your data.
- **Objection:** Object to processing based on legitimate interests or for direct marketing.
- **Withdraw Consent:** Withdraw any consent provided at any time.

To exercise these rights, please contact us at the information provided below.

## 8. Data Security

We employ industry-standard security measures to protect your data from unauthorised access, loss, or misuse. However, please be aware that no security system is foolproof.

## 9. Data Retention

We retain your personal information as long as necessary to provide services and fulfill the purposes outlined in this policy, or as required by law. Upon request, or when no longer needed, we securely delete or anonymise your data.

## 10. Cookies and Similar Technologies

We use cookies and similar tracking technologies to improve functionality and personalise your experience. You may manage your cookie preferences in your browser or through our settings. Please refer to our Cookie Policy for more details.

## 11. Opt-Out Options

- **Marketing Communications:** You may opt-out of receiving marketing communications at any time by following the unsubscribe link in emails or contacting us.
- **Interest-Based Advertising:** To opt-out of interest-based ads, adjust your cookie settings or manage your preferences with third-party advertisers.

## 12. Connecting Third-Party Services

End Users have the option to opt-in to share additional Personal Information from third-party social and music services, including but not limited to Facebook, Twitter, YouTube, Deezer, Spotify, and Apple Music. This information allows us to provide a more personalised experience and enables seamless engagement with music and content across platforms.

Absolute Label Services Ltd's use and transfer of any data received from Google APIs fully complies with Google API Services User Data Policy, including its Limited Use requirements.

## 13. Removing Third-Party Service Access

If you have linked a third-party service to Slinky.to and wish to remove this access, you can do so by logging into the respective service and managing app permissions within their settings:

- **YouTube:** Visit [https://security.google.com/settings/security/permissions](https://security.google.com/settings/security/permissions)
- **Spotify:** Visit [https://www.spotify.com/us/account/apps/](https://www.spotify.com/us/account/apps/)
- **Apple Music:** 
  - On iOS devices: Settings > Privacy > Media & Apple Music
  - On desktop: Apple Music > Account > View My Account > Account Access > Manage
- **Deezer:** Visit [https://www.deezer.com/account/apps](https://www.deezer.com/account/apps)

## 14. Children's Privacy

Our services are not intended for users under the age of 13 (or 16 in the EU). We do not knowingly collect information from children and will promptly delete any such data upon notification.

## 15. Changes to This Privacy Policy

We may update this Privacy Policy periodically. Significant changes will be communicated through our website or by direct notification, where possible.

## 16. Contact Us

If you have any questions or concerns about this Privacy Policy, please contact us at:

Absolute Label Services Ltd (Slinky.to)

Email: [dpo@absolutelabelservices.com](mailto:dpo@absolutelabelservices.com)`;
