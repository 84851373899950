import {
  ButtonWrapper,
  CircleIconButton,
  ImageWrapper,
} from "@anthology/shared/src/components";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Box,
  Checkbox,
  DialogActions,
  DialogContentText,
  FormControlLabel,
  Grow,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  UseFormReturn,
  useForm,
} from "react-hook-form";
import { BsX } from "react-icons/bs";
import * as yup from "yup";
import { handlePixelEvent } from "..";
import { ArtistVm, PreSaveVm, UpdatePreSaveVm, slinkyApi } from "../../..";
import { Destinations, Platforms } from "../../../api/types/autoEnum";
import { privacyUrl } from "../../../constants/urls";
import { PixelEventType } from "../handlePixels";
import { SlinkyDialog } from "../slinkyDialog/SlinkyDialog";
import style from "./SlinkyPreSaveDialog.module.scss";

const artistsToString = (artists: ArtistVm[]) => {
  if (!artists || artists.length === 0) return "";
  if (artists.length === 1) return artists[0].displayName || "";
  const displayArtists = [...artists];
  const lastArtist = displayArtists.pop();
  return `${displayArtists.map((artist) => artist.displayName).join(", ")} and ${lastArtist?.displayName}`;
};

// const PlatformColours = [
//   [Platforms.Spotify, "#1DB954"],
//   [Platforms.Apple_Music, "#FF3258"],
//   [Platforms.YouTube_Music, "#FF0000"],
// ];

// const PlatformTextField = styled(TextField)<{ platformId: Platforms }>`
//   & label.Mui-focused {
//     color: ${({ platformId }) =>
//       PlatformColours.find(([platform]) => platform === platformId)?.[1]};
//   }

//   & .MuiOutlinedInput-root {
//     &.Mui-focused fieldset {
//       border-color: ${({ platformId }) =>
//         PlatformColours.find(([platform]) => platform === platformId)?.[1]};
//     }
//   }
// `;
// const PlatformButton = styled(ButtonWrapper)<{ platformId: Platforms }>`
//   background-color: ${({ platformId }) =>
//     PlatformColours.find(([platform]) => platform === platformId)?.[1]};
//   &:hover {
//     background-color: ${({ platformId }) =>
//       PlatformColours.find(([platform]) => platform === platformId)?.[1]};
//   }
//   &:focus {
//     background-color: ${({ platformId }) =>
//       PlatformColours.find(([platform]) => platform === platformId)?.[1]};
//   }
// `;

export type SlinkyPresavePlatform =
  | Platforms.Apple_Music
  | Platforms.Spotify
  | Platforms.YouTube_Music;

export interface SlinkyPreSaveDialogProps {
  slinkyId: number;
  open: boolean;
  onClose: (isSuccess?: boolean, isError?: boolean) => void;
  presaveProps?: SlinkyPresaveProps;
  artists?: ArtistVm[];
  slinkyImage?: string;
  slinkyTitle?: string;
}

export interface SlinkyPresaveProps {
  ps?: string;
  platformId: any; //SlinkyPresavePlatform;
  destinationLinkId: number;
  destinationFormatId: string | null;
  destinationName?: string;
  formatLinkId?: number | null;
  noPlaylists?: boolean;
  userAppleMusicToken?: string;
  customDestinationSubName?: string;
  formatShortName?: string;
}

const SlinkyPreSaveDialogTop = ({
  productName,
  slinkyImage,
  artistNames,
}: {
  slinkyImage?: string;
  productName: string;
  artistNames: string;
}) => {
  return (
    <Stack gap={0.5} alignItems={"center"}>
      {slinkyImage && (
        <ImageWrapper
          width={130}
          height={130}
          src={slinkyImage}
          style={{ marginBottom: 15 }}
        />
      )}
      <Typography variant="h3" color="text.secondary" textAlign={"center"}>
        {productName}
      </Typography>
      <Typography variant="labelMedium" color="text.secondary">
        {artistNames}
      </Typography>
    </Stack>
  );
};

const ArtistCheckboxes = ({
  artists,
  hookForm,
}: {
  artists: ArtistVm[];
  hookForm: UseFormReturn<any>;
}) => {
  const artistIdsFieldName = "followArtists";
  const { control, setValue } = hookForm;

  return (
    <Stack gap={1}>
      <Typography variant="labelLarge">Receive future updates from</Typography>
      {artists.map((artist) => {
        const { displayName, artistId } = artist;
        if (!artistId) return null;

        return (
          <Controller
            key={artistId}
            name={artistIdsFieldName}
            control={control}
            render={({ field }) => {
              const selectedArtistIds = field.value || [];
              const isChecked = selectedArtistIds.includes(artistId);

              const handleChange = (
                event: React.ChangeEvent<HTMLInputElement>
              ) => {
                const checked = event.target.checked;
                const newSelectedArtistIds = checked
                  ? [...selectedArtistIds, artistId]
                  : selectedArtistIds.filter(
                      (selectedArtistId: number) =>
                        selectedArtistId !== artistId
                    );

                setValue(artistIdsFieldName, newSelectedArtistIds, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              };

              return (
                <FormControlLabel
                  control={
                    <Checkbox checked={isChecked} onChange={handleChange} />
                  }
                  label={displayName}
                />
              );
            }}
          />
        );
      })}
    </Stack>
  );
};

const SignupFooterContent = ({
  artists,
  destinationName,
  missingArtistProfiles,
}: {
  artists?: ArtistVm[];
  destinationName?: string;
  missingArtistProfiles?: number[];
}) => {
  const artistsString = artistsToString(artists || []);
  const noArtistsToFollow = missingArtistProfiles?.length === artists?.length;
  const followAllArtists =
    !missingArtistProfiles || missingArtistProfiles.length === 0;
  const followArtistsString = artistsToString(
    artists?.filter(
      (artist) =>
        artist?.artistId && !missingArtistProfiles?.includes(artist?.artistId)
    ) || []
  );
  return (
    <Typography variant="bodyMedium" marginTop="14px" component={Box}>
      The release will be added to your library on the release date. We will
      send you an email with a link to access it.{" "}
      {noArtistsToFollow ? (
        <>
          You will receive updates and new releases from {artistsString}, their
          label, and Slinky.
        </>
      ) : (
        <>
          As well as following {followArtistsString} on {destinationName} you
          will receive new releases and updates from{" "}
          {followAllArtists ? "them" : artistsString}, their label and Slinky.
        </>
      )}{" "}
      <a target="_blank" href={privacyUrl}>
        Privacy&nbsp;Policy
      </a>
    </Typography>
  );
};

const SlinkyPreSaveDialogSignUp = ({
  artists,
  presaveHookForm,
  isLoading,
  destinationName,
  destinationLinkId,
}: Partial<SlinkyPresaveProps> & {
  artists?: ArtistVm[];
  presaveHookForm: UseFormReturn<any>;
  isLoading: boolean;
  productName: string;
  destinationLinkId?: number;
}) => {
  const artistsString = artistsToString(artists || []);

  return (
    <Stack gap={3} my={2}>
      <Typography variant="bodyMedium" component={Box}>
        Sign up below to be the first to receive new releases and updates from{" "}
        {artistsString}.
      </Typography>
      {isLoading ? (
        <InputSkeleton />
      ) : (
        <Controller
          name="email"
          control={presaveHookForm.control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              size="small"
              label="Email"
              disabled={isLoading}
            />
          )}
        />
      )}
      <ArtistCheckboxes artists={artists || []} hookForm={presaveHookForm} />
      <SignupFooterContent
        artists={artists}
        destinationName={destinationName}
        missingArtistProfiles={
          artists
            ?.filter(
              (artist) =>
                !destinationLinkId ||
                !artist.artistIdentifiers?.find(
                  (identifier) =>
                    identifier.destinationLinkId === destinationLinkId
                )
            )
            .map((a) => a.artistId || 0) || []
        }
      />
    </Stack>
  );
};

const InputSkeleton = () => <Skeleton width={"100%"} height={75} />;

export function SlinkyPreSaveDialog({
  slinkyId,
  open,
  onClose,
  presaveProps,
  artists,
  slinkyImage,
  slinkyTitle,
}: SlinkyPreSaveDialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    platformId,
    formatLinkId,
    destinationLinkId,
    destinationFormatId,
    destinationName,
    userAppleMusicToken,
    customDestinationSubName,
    formatShortName,
  } = presaveProps || {};

  const productName = `${slinkyTitle}${customDestinationSubName ? ` - ${customDestinationSubName}` : ""}${formatShortName ? ` (${formatShortName})` : ""}`;

  const isUniquePlaylistName = (value?: string) =>
    !playlistOptions?.some((playlist) => playlist.text === value);

  const presaveValidationSchema = yup.object().shape({
    saveTargetId: yup.string(),
    saveTargetName: yup.string().when("saveTargetId", {
      is: "new",
      then: (schema) =>
        schema
          .required("Required")
          .test(
            "unique",
            "This playlist name is already in use",
            isUniquePlaylistName
          ),
    }),
  });

  const updateValidationSchema = yup.object().shape({
    followArtists: yup.array().of(yup.number()),
    email: yup.string().email("Please provide a valid email").required(),
  });

  const presaveHookForm = useForm<PreSaveVm>({
    mode: "onChange",
    defaultValues: {
      saveTargetId: null,
      saveTargetName: "",
      followArtists: [],
    },
    resolver: yupResolver(presaveValidationSchema),
  });

  const updateHookForm = useForm<UpdatePreSaveVm>({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(updateValidationSchema),
  });

  const isAppleMusic = platformId === Platforms.Apple_Music;
  const isSpotify = platformId === Platforms.Spotify;
  const isYouTubeMusic = platformId === Platforms.YouTube_Music;

  const [getPlaylistsTrigger, playlistsState] =
    slinkyApi.useLazyGetApiSlinkyPreSaveGetUserPlaylistsQuery();
  const [presaveTrigger, presaveState] =
    slinkyApi.usePostApiSlinkyPreSavePreSaveMutation();
  const [updateTrigger, updateState] =
    slinkyApi.usePutApiSlinkyPreSaveUpdatePreSaveMutation();

  const [userId, setUserId] = useState<string>("");
  useEffect(() => {
    if (presaveProps?.platformId === Platforms.Apple_Music && open) {
      presaveTrigger({
        guid: presaveProps?.ps!,
        formatLinkId: formatLinkId,
        slinkyLinkId: slinkyId,
        formatPlatformId: destinationFormatId || null,
        destinationId: Destinations.Apple_Music,
        token: userAppleMusicToken,
      }).then((res) => {
        const resObj = res as any;
        if (typeof resObj.data === "string") {
          setUserId(resObj.data);
        }
      });
    }
  }, [open]);

  const playlistOptions =
    playlistsState?.data &&
    playlistsState.data.map((option) => {
      const newNameIndex = option.text?.toLowerCase().indexOf("new playlist");
      const isNewPlaylist = newNameIndex && newNameIndex > -1;
      return {
        ...option,
        id: option.id || (isNewPlaylist ? "new" : null),
      };
    });

  const isLoading =
    presaveState?.isLoading ||
    playlistsState?.isLoading ||
    updateState?.isLoading;

  useEffect(() => {
    const hasProps = !!presaveProps?.ps && !!presaveProps.destinationLinkId;
    const platformPlaylistMatch =
      (presaveProps?.platformId === Platforms.YouTube_Music &&
        !presaveProps?.noPlaylists) ||
      ![Platforms.YouTube_Music, Platforms.Apple_Music].includes(
        presaveProps?.platformId
      );
    if (hasProps && platformPlaylistMatch) {
      getPlaylistsTrigger({
        ps: presaveProps.ps!,
        p: presaveProps.destinationLinkId,
      });
    }
  }, [presaveProps]);

  useEffect(() => {
    const artistIds = artists?.map((artist) => artist.artistId || 0) || [];
    presaveHookForm.setValue("followArtists", artistIds);
    updateHookForm.setValue("followArtists", artistIds);
  }, [artists]);

  const handleClose = (type: "success" | "cancel" | "error") => {
    const isSuccess = type === "success";
    const isError = type === "error";
    if (isSuccess) {
      handlePixelEvent(
        PixelEventType.PreSaveEnd,
        destinationName,
        destinationLinkId
      );
    } else if (type === "cancel") {
      handlePixelEvent(
        PixelEventType.PreSaveCancelled,
        destinationName,
        destinationLinkId
      );
    }
    onClose(isSuccess, isError);
  };

  const onSubmitPresave = (formData: PreSaveVm) => {
    const { saveTargetId, saveTargetName, followArtists, requiresEmailOutNow } =
      formData;
    const isBlankTargetId =
      !saveTargetId || [null, "new"].includes(saveTargetId);
    const isYouTubeNoTarget = isYouTubeMusic && !saveTargetId;
    const targetId = isYouTubeNoTarget
      ? null
      : isBlankTargetId
        ? null
        : saveTargetId;
    const isNewPlaylist = saveTargetId === "new";
    const presaveData: PreSaveVm = {
      guid: presaveProps?.ps!,
      slinkyLinkId: slinkyId,
      formatLinkId: formatLinkId,
      formatPlatformId: destinationFormatId || null,
      saveTargetId: targetId,
      saveTargetName: isNewPlaylist
        ? saveTargetName
        : isYouTubeNoTarget
          ? `${slinkyTitle} - ${artistNames}`
          : null,
      referringUrl: null,
      accountType: null,
      requiresEmailOutNow: requiresEmailOutNow,
      followArtists: followArtists,
      destinationId: destinationLinkId,
    };

    presaveTrigger(presaveData).then((res: any) => {
      presaveHookForm.reset();
      if (res.error) {
        handleClose("error");
      } else {
        handleClose("success");
      }
    });
  };

  const onSubmitUpdate = (formData: UpdatePreSaveVm) => {
    updateTrigger({ id: userId, slinkyId: slinkyId, ...formData }).then(
      (res: any) => {
        if (formData.email) {
          handlePixelEvent(
            PixelEventType.Lead,
            destinationName,
            destinationLinkId
          );
        }
        handleClose(res.isError ? "error" : "success");
      }
    );
  };

  const watchShowNewPlaylistInput = presaveHookForm.watch("saveTargetId");
  const isNewPlaylist = watchShowNewPlaylistInput === "new";

  // const loadingBar = isLoading ? (
  //   <LinearProgress
  //     sx={{
  //       my: 2,
  //     }}
  //   />
  // ) : (
  //   <></>
  // );

  const artistNames = artistsToString(artists || []);

  const presaveDialogContent = (
    <>
      <DialogContentText className={style.content} component={Box}>
        <SlinkyPreSaveDialogTop
          productName={productName}
          slinkyImage={slinkyImage}
          artistNames={artistNames}
        />
        {/* {loadingBar} */}
        {!presaveProps?.noPlaylists &&
          (isLoading ? (
            <InputSkeleton />
          ) : (
            <Controller
              name="saveTargetId"
              control={presaveHookForm.control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  select
                  label="Destination"
                  disabled={isLoading}
                >
                  {playlistOptions?.map((option: any) => (
                    <MenuItem key={option.id} value={option.id || 0}>
                      {option.text}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          ))}
        {playlistsState.error ? (
          <Alert color="error" icon={<></>}>
            Error loading your playlists
          </Alert>
        ) : (
          <></>
        )}
        {isNewPlaylist && (
          <Controller
            name="saveTargetName"
            control={presaveHookForm.control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="New Playlist name"
                size="small"
                disabled={isLoading}
              />
            )}
          />
        )}
        <ArtistCheckboxes artists={artists || []} hookForm={presaveHookForm} />
        <SignupFooterContent
          artists={artists}
          destinationName={destinationName}
          missingArtistProfiles={
            artists
              ?.filter(
                (artist) =>
                  !destinationLinkId ||
                  !artist.artistIdentifiers?.find(
                    (identifier) =>
                      identifier.destinationLinkId === destinationLinkId
                  )
              )
              .map((a) => a.artistId || 0) || []
          }
        />
      </DialogContentText>
    </>
  );

  const updateDialogContent = (
    <>
      <DialogContentText className={style.content} component={Box}>
        <SlinkyPreSaveDialogTop
          productName={productName}
          slinkyImage={slinkyImage}
          artistNames={artistNames}
        />

        <SlinkyPreSaveDialogSignUp
          {...presaveProps}
          artists={artists}
          presaveHookForm={updateHookForm}
          isLoading={isLoading}
          productName={productName}
          destinationLinkId={destinationLinkId}
        />
      </DialogContentText>
    </>
  );

  // const appleDialogContent = (
  //   <>
  //     <DialogContentText className={style.content} component={Box}>
  //       <SlinkyPreSaveDialogTop
  //         productName={productName}
  //         slinkyImage={slinkyImage}
  //         artistNames={artistNames}
  //       />
  //       {/* {loadingBar} */}
  //       <SlinkyPreSaveDialogSignUp
  //         {...presaveProps}
  //         artists={artists}
  //         presaveHookForm={updateHookForm}
  //         isLoading={isLoading}
  //         productName={productName}
  //       />
  //     </DialogContentText>
  //   </>
  // );

  const actions = (
    <DialogActions sx={{ marginTop: "12px", padding: 0 }}>
      <ButtonWrapper
        variant="contained"
        type="submit"
        isLoading={isLoading}
        disabled={!presaveHookForm.formState.isValid}
      >
        {isSpotify ? "Pre-Save" : "Submit"}
      </ButtonWrapper>
    </DialogActions>
  );

  const { errors } = presaveHookForm.formState || {};

  return (
    <SlinkyDialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      open={open}
      TransitionComponent={Grow}
      onClose={() => handleClose("cancel")}
      title={"Presave"}
      PaperProps={
        slinkyImage
          ? {
              className: style.dialog,
              sx: { backgroundImage: `url(${slinkyImage})` },
            }
          : {}
      }
    >
      <Box className={style.dialogInner}>
        <Stack direction="row" justifyContent={"flex-end"}>
          <CircleIconButton onClick={() => handleClose("cancel")}>
            <BsX />
          </CircleIconButton>
        </Stack>
        <Box p={4}>
          {platformId ? (
            !isAppleMusic ? (
              <FormProvider {...presaveHookForm}>
                <form
                  id="presave-form"
                  onSubmit={presaveHookForm.handleSubmit(onSubmitPresave)}
                >
                  {presaveDialogContent}
                  {errors && <ul></ul>}
                  {actions}
                </form>
              </FormProvider>
            ) : (
              <FormProvider {...presaveHookForm}>
                <form
                  id="update-form"
                  onSubmit={updateHookForm.handleSubmit(onSubmitUpdate)}
                >
                  {updateDialogContent}
                  {errors && <ul></ul>}
                  {actions}
                </form>
              </FormProvider>
            )
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </SlinkyDialog>
  );
}
